import { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import AdminPrivateRoute from "./pages/auth/privateRoute";
import { setHeaders } from "./helpers/requestHeaders";
import { useDispatch } from "react-redux";
import { setLogin } from "./slice/loginSlice";

const Login = lazy(() => import("./pages/auth/login"));
const Layout = lazy(() => import("./pages/layout"));
const Home = lazy(() => import("./pages/home"));
const Users = lazy(() => import("./pages/users"));
const Query = lazy(() => import("./pages/query"));
const OpenQuery = lazy(() => import("./pages/query/openQuery"));
const ClosedQuery = lazy(() => import("./pages/query/closedQuery"));
const QueryDetails = lazy(() => import("./pages/query/queryDetails"));

const Loading = lazy(() => import("./component/loading/index"));
const pageNotFound = lazy(() => import("./component/loading"));

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    setHeaders();
    const login = JSON.parse(localStorage.getItem("login"));
    if (login) {
      dispatch(setLogin(login));
    }
  }, []);
  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<AdminPrivateRoute />}>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="query" element={<Query />}>
                <Route path="open" element={<OpenQuery />} />
                <Route path="closed" element={<ClosedQuery />} />
                <Route path="open/:id" element={<QueryDetails />} />
                <Route path="close/:id" element={<QueryDetails />} />
              </Route>
              <Route path="*" element={<pageNotFound />} />
            </Route>
          </Route>
          <Route path="*" element={<pageNotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
